export const salesData = [
    {
      date: 'Mon',
      Total: 4000
    },
    {
      date: 'Tue',
      Total: 3000
    },
    {
      date: 'Wed',
      Total: 2000
    },
    {
      date: 'Thu',
      Total: 2780
    },
    {
      date: 'Fri',
      Total: 1890
    },
    {
      date: 'Sat',
      Total: 2390
    },
    {
      date: 'Sun',
      Total: 3490
    },
];

export const BASE_URL = 'https://admin.orderonline.hk/mobileapp_api/superAdmin/';

export const IMAGE_URL = 'https://admin.orderonline.hk/mobileapp_api/';

export const BANNER_URL = 'https://admin.orderonline.hk/mobileapp_api/app/webroot/uploads/banners/';