import React from 'react'
import { Grid, Paper, Button, IconButton, TextField, Dialog, DialogContent, DialogActions, Chip } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const ExtraItemForm = (props) => {
    const paperStyle = { padding: '0 15px 10px 15px', width: 300 }
    const btnStyle = { margin: '15px 50px 0' , width: 200 }

    const initialValues = {
        name: '',
        price: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, "It's too short").required("Required"),
        price: Yup.number().required("Required")
    })

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSubmit = (values) => {
        props.onSubmit(values)
        handleClose()
    }

    return ([
        <Chip
            key={'0'}
            label='Add Item'
            color='secondary'
            variant='outlined'
            icon={
                <Add />
            }
            onClick={handleClickOpen}
            style={{margin: '5px'}}
        />,
        <Dialog key={'1'} open={open} onClose={handleClose}>
            <DialogActions >
                <div className="formTitle">Add New Extra Item</div>
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogContent>
                <Grid>
                    <Paper elevation={0} style={paperStyle}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
                            {(props) => (
                                <Form noValidate>
                                    <Field as={TextField} name='name' label='Name' fullWidth
                                        error={props.errors.name && props.touched.name}
                                        helperText={<ErrorMessage name='name' />} required />
                                    <Field as={TextField} name='price' label='Price' fullWidth
                                        error={props.errors.price && props.touched.price}
                                        helperText={<ErrorMessage name='price' />} required />
                                    <Button type='submit' style={btnStyle} variant='contained'color='primary'>Add</Button>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </DialogContent>
        </Dialog>
    ])
}

export default ExtraItemForm