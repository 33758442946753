import { useState } from 'react'

export default function useToken() {

    function getToken() {
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    }
    
    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
        if(userToken != null){
            localStorage.setItem('token', JSON.stringify(userToken))
            setToken(userToken)
        }else{
            localStorage.removeItem('token')
            setToken(getToken())
        }
    }

    return {
        setToken: saveToken,
        token
    }

}