import React from 'react';
import LoginForm from './components/form/LoginForm'
import Sidebar from './components/sidebar/Sidebar'
import Topbar from './components/topbar/Topbar'
import Home from './pages/home/Home'
import UserList from './pages/userList/UserList'
import RestaurantList from './pages/restaurantList/RestaurantList'
import RestaurantMenu from './pages/restaurantMenu/RestaurantMenu'
import Kitchen from './pages/kitchen/Kitchen'
import District from './pages/district/District'
import OrderList from './pages/orderList/OrderList'
import Analytics from './pages/analytics/Analytics'
import './app.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core'
import useToken from './components/function/UseToken'


const theme = createTheme({
  palette: {
    primary:{
      main: '#cb202d'
    },
    secondary:{
      main: '#666'
    }
  }
})

function App() {

  const { token, setToken } = useToken()

  if(!token) {
    return <ThemeProvider theme={theme}><LoginForm onSubmit={setToken} /></ThemeProvider>
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Topbar/>
        <div className="container">
          
          <Sidebar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/analytics">
              <Analytics />
            </Route>
            <Route path="/users">
              <UserList />
            </Route>
            <Route path="/restaurants">
              <RestaurantList />
            </Route>
            <Route path="/menu/:restaurant_id" component={RestaurantMenu} />
            <Route path="/kitchens">
              <Kitchen />
            </Route>
            <Route path="/orders">
              <OrderList />
            </Route>
            <Route path="/districts">
              <District />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
