import React, { Component } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './userList.css'
import axios from 'axios'
import Moment from 'moment'
import * as config from '../../config'

class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: []
        }
    }
    
    componentDidMount() {
        axios.post(config.BASE_URL + 'showUsers', {})
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let user = {
                        id: info.User.id,
                        email: info.User.email,
                        name: info.UserInfo.first_name + ' ' + info.UserInfo.last_name,
                        phone: info.UserInfo.phone,
                        date: Moment(info.User.created).format('DD MMM YY, HH:MM')
                    }
                    temp.push(user)
                    return null
                })
                this.setState({users: temp})
            })
            .catch(error => {
                console.log(error)
            })
    }
    
    render() {
        const { users } = this.state
        const columns = [
            { field: 'id',
              headerName: 'ID',
              type: 'number',
              width: 150
            },
            {
              field: 'name',
              headerName: 'Name',
              width: 250,
              editable: false,
            },
            {
              field: 'email',
              headerName: 'Email',
              width: 260,
              editable: false,
            },
            {
              field: 'phone',
              headerName: 'Phone',
              width: 150,
              editable: false,
            },
            {
                field: 'date',
                headerName: 'Joined Date',
                width: 200,
                type: 'date',
                editable: false,
                sortable: false
              }
        ];
        
        return (
            <div className="userList">
                {
                    users.length ?
                    <DataGrid rows={users} columns={columns} /> :
                    <div className="userListLoading">Loading...</div>
                }
            </div>
        )
    }
}

export default UserList
