import './topbar.css'
import { NotificationsNone, Settings } from '@material-ui/icons';

export default function Topbar(){
    return(
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <img className="logo" alt="" src="https://admin.orderonline.hk/mobileapp_api/app/webroot/uploads/logo.png"></img>
                </div>
                <div className="topRight">
                    <div className="topbarIconContainer">
                        <NotificationsNone />
                        <span className="topIconBadge">3</span>  
                    </div>
                    <div className="topbarIconContainer">
                        <Settings/>
                    </div>
                </div>
            </div>
        </div>
    )
}