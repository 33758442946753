import React from 'react'
import { Grid, Paper, Button, Snackbar} from '@material-ui/core'
import { TextField, InputLabel, Select, MenuItem, FormControl, FormHelperText} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import * as config from '../../config'

const KitchenForm = (props) => {
    const [open, setOpen ] = React.useState(false);
    const [districts, setDistricts] = React.useState([]);

    React.useEffect(() => {
        axios.post(config.BASE_URL + 'getAllDistricts', {})
        .then(response => {
            setDistricts(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    })

    const paperStyle = { padding: '0 15px 10px 15px', width: 300 }
    const btnStyle = { margin: '15px 50px 0' , width: 200 }
    const phoneRegExp=/^[+]*[8][5][2][2-9]{1}[0-9]{7}/

    const initialValues = {
        name: '',
        district: '',
        address: '',
        phone: '',
        device: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, "It's too short").required("Required"),
        address: Yup.string().min(20, "Enter the full address").required("Required"),
        district: Yup.string().min(1, "Select the district").required("Required"),
        phone: Yup.string().matches(phoneRegExp, "Enter a valid phone number").required("Required"),
        device: Yup.string().length(16, "Enter a valid device id")
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <Grid>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='left'>
                    *Note : get Device ID from the OOHK Kitchen APP.
                </Grid>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
                    {(props) => (
                        <Form noValidate>

                            <Field as={TextField} name='name' label='Name' fullWidth
                                error={props.errors.name && props.touched.name}
                                helperText={<ErrorMessage name='name' />} required />
                            <FormControl fullWidth>
                                <InputLabel id="label">District</InputLabel>
                                <Field as={Select} name="district" labelId="label" fullWidth
                                    error={props.errors.district && props.touched.district} required
                                >
                                    {
                                        districts.map(data => {
                                            return <MenuItem value={data.id}> { data.name } </MenuItem>
                                        })
                                    }
                                </Field>
                                <FormHelperText error>{<ErrorMessage name="district" />}</FormHelperText>
                            </FormControl>
                            <Field as={TextField} name='address' label='Address' fullWidth
                                error={props.errors.address && props.touched.address}
                                helperText={<ErrorMessage name='address' />} required />

                            <Field as={TextField} name="phone" label='Phone' fullWidth
                                error={props.errors.phone && props.touched.phone}
                                helperText={<ErrorMessage name='phone' />} required />

                            <Field as={TextField} name='device' label='Device ID' fullWidth
                                error={props.errors.device && props.touched.device}
                                helperText={<ErrorMessage name='device' />}/>

                            <Button type='submit' style={btnStyle} variant='contained'color='primary'>Add</Button>
                        </Form>
                    )}
                </Formik>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Added successfully."
            />
        </Grid>
    )
}

export default KitchenForm