import React, { Component } from 'react'
import axios from 'axios'
import * as config from '../../config'
import './form.css'
import { IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { AddCircleOutlined, Delete } from '@material-ui/icons'

export class TimeTableForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timing: [],
            loading: false
        }
    }
    
    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showRestaurantTiming', {id: this.props.id})
            .then(response => {
                let temp_timing= []
                response.data.timing.map(info => {
                    let time = {
                        id: info.RestaurantTiming.id,
                        day: info.RestaurantTiming.day,
                        opening_time: info.RestaurantTiming.opening_time,
                        closing_time: info.RestaurantTiming.closing_time,
                    }
                    temp_timing.push(time)
                    return null
                })
                this.setState({timing: temp_timing, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { timing, loading } = this.state
        const days = [
            {
                id: '1',
                name: 'Monday'
            },
            {
                id: '2',
                name: 'Tuesday'
            },
            {
                id: '3',
                name: 'Wednesday'
            },
            {
                id: '4',
                name: 'Thursday'
            },
            {
                id: '5',
                name: 'Friday'
            },
            {
                id: '6',
                name: 'Saturday'
            },
            {
                id: '7',
                name: 'Sunday'
            }
        ]
        return (
            <div className="timing">
                {
                    loading ?
                    <div className="timingListLoading">Loading...</div>:
                    <div className="timingList">
                        {
                            timing.map(time => {
                                return <div className="timingRow" key={time.id}>
                                    <div className="timingAction">
                                        <IconButton>
                                            <AddCircleOutlined />
                                        </IconButton>
                                    </div>
                                    <div className="timingData">
                                        <InputLabel id='day' shrink={true}>Day</InputLabel>
                                        <Select
                                            labelId='day'
                                            value={time.day}
                                            label='Day'
                                            fullWidth
                                        >
                                            {
                                                days.map(day =>{
                                                    return <MenuItem value={day.name} key={day.id}>{day.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                    <div className="timingData">
                                        <TextField
                                            id="time"
                                            label="Opening Time"
                                            type="time"
                                            defaultValue={time.opening_time}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="timingData">
                                        <TextField
                                            id="time"
                                            label="Closing Time"
                                            type="time"
                                            defaultValue={time.closing_time}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="timingAction">
                                        <IconButton>
                                            <Delete color='primary' />
                                        </IconButton>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default TimeTableForm

