import React, { Component } from 'react'
import './restaurantList.css'
import axios from 'axios'
import * as config from '../../config'
import { Card, CardHeader, CardMedia, Avatar, CardActions, IconButton, Switch, Snackbar } from '@material-ui/core'
import { MenuBook } from '@material-ui/icons'
import TimeTable from '../../components/popup/TimeTable'
import RestaurantForm from '../../components/form/RestaurantForm'
import TimeTableForm from '../../components/form/TimeTableForm'
import { Link } from 'react-router-dom'

class RestaurantList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            restaurants: [],
            loading: false,
            spin: false
        }
    }
    
    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showAllRestaurants', {})
            .then(response => {
                let temp = []
                if(response.data.code === 200){
                    response.data.restaurants.map(info => {
                        let restaurant = {
                            id: info.Restaurant.id,
                            name: info.Restaurant.name,
                            description: info.Restaurant.slogan,
                            logo: config.IMAGE_URL + info.Restaurant.image,
                            cover: config.IMAGE_URL + info.Restaurant.cover_image,
                            banner: config.IMAGE_URL + info.Restaurant.banner,
                            email: info.User.email,
                            kitchen: info.Kitchen.id,
                            self_pickup: info.Restaurant.self_pickup === '1' ? true : false,
                            block: info.Restaurant.block
                        }
                        temp.push(restaurant)
                        return null
                    })
                    this.setState({restaurants: temp, loading: false})
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    
    render() {
        const { restaurants, loading, spin } = this.state

        const addRestaurant = (values) => {

            this.setState({spin: true})
            const data = new FormData()
            data.append('name', values.name)
            data.append('slogan', values.slogan)
            data.append('email', values.email)
            data.append('kitchen', values.kitchen)
            data.append('self_pickup', values.self_pickup ? '1' : '0')

            if(values.banner != null) data.append('banner', values.banner, values.banner.name)
            if(values.logo != null) data.append('logo', values.logo, values.logo.name)
            if(values.cover != null) data.append('cover', values.cover, values.cover.name)

            axios.post(config.BASE_URL + 'addRestaurant', data)
            .then(response => {
                if(response.data.code === 200){
                    let new_restaurant = {
                        id: response.data.id,
                        name: values.name,
                        description: values.slogan,
                        email: values.email,
                        kitchen: values.kitchen,
                        self_pickup: values.self_pickup,
                        block: '1'
                    }
                    response.data.banner ? new_restaurant.banner = config.IMAGE_URL + response.data.banner : new_restaurant.banner = ''
                    response.data.logo ? new_restaurant.logo = config.IMAGE_URL + response.data.logo : new_restaurant.logo = ''
                    response.data.cover ? new_restaurant.cover = config.IMAGE_URL + response.data.cover : new_restaurant.cover = ''

                    this.setState({restaurants : [...restaurants, new_restaurant]})

                }else{
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const updateRestaurant = (values, id) => {

            this.setState({spin: true})
            const data = new FormData()
            data.append('restaurant_id', id)
            data.append('name', values.name)
            data.append('slogan', values.slogan)
            data.append('email', values.email)
            data.append('kitchen', values.kitchen)
            data.append('self_pickup', values.self_pickup ? '1' : '0')

            if(values.banner != null) data.append('banner', values.banner, values.banner.name)
            if(values.logo != null) data.append('logo', values.logo, values.logo.name)
            if(values.cover != null) data.append('cover', values.cover, values.cover.name)

            axios.post(config.BASE_URL + 'updateRestaurant', data)
            .then(response => {
                if(response.data.code === 200){
                    let info = {
                        name: values.name,
                        description: values.slogan,
                        email: values.email,
                        kitchen: values.kitchen,
                        self_pickup: values.self_pickup
                    }
                    response.data.banner ? info.banner = config.IMAGE_URL + response.data.banner : info.banner = ''
                    response.data.logo ? info.logo = config.IMAGE_URL + response.data.logo : info.logo = ''
                    response.data.cover ? info.cover = config.IMAGE_URL + response.data.cover : info.cover = ''

                    this.setState({restaurants: restaurants.map(restaurant => (restaurant.id === id ? {...restaurant, ...info} : restaurant ))})

                }else{
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const updateRestaurantStatus = (event, id) => {
            this.setState({spin: true})
            let data = {
                id: id,
                block: event.target.checked ? '0' : '1'
            }
            axios.post(config.BASE_URL + 'updateRestaurantStatus', data)
            .then(response => {
                this.setState({spin: false})
                if(response.data.code === 200){
                    this.setState({restaurants: restaurants.map(restaurant => (restaurant.id === id ? {...restaurant, ...data} : restaurant ))})
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteRestaurant = (id) => {
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'deleteRestaurant', {restaurant_id: id})
            .then(response => {
                if(response.data.code === 200){
                    this.setState({ restaurants: restaurants.filter(restaurant => restaurant.id !== id)})
                } else {
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }
        
        return (
            loading ?
            <div className="restaurantListLoading">Loading...</div>:
            <div className="restaurantList">
                <div className="restaurantButton">
                    <RestaurantForm onSubmit={addRestaurant}/>
                </div>
                <div className="restaurantCategoryTitle">
                    Active Restaurants
                </div>
                <div className="restaurantCards">
                    {
                        restaurants.filter(res => res.block === '0').map(restaurant => {
                            return <Card key={restaurant.id} className="restaurantCard">
                                <CardHeader
                                    avatar={
                                        <Avatar 
                                            alt={restaurant.name}
                                            src={restaurant.logo}
                                        />
                                    }
                                    title = {restaurant.name}
                                    subheader={restaurant.description}
                                    action = {
                                        <Switch onChange={(event) => updateRestaurantStatus(event, restaurant.id)} color='primary' checked={restaurant.block === '0'} />
                                    }
                                />
                                <CardMedia 
                                    image = {restaurant.cover + '#image/png'}
                                    className = "restaurantCardImage"
                                />
                                <CardActions className="restaurantCardAction">
                                    <RestaurantForm restaurant={restaurant} onSubmit={values => updateRestaurant(values, restaurant.id)} onDelete={() => deleteRestaurant(restaurant.id)} />
                                    <TimeTable title={restaurant.name + ' Schedule'}>
                                        <TimeTableForm id={restaurant.id} />
                                    </TimeTable>
                                    <Link to={"/menu/" + restaurant.id} style = {{textDecoration: 'none', color: 'inherit'}}>
                                        <IconButton>
                                            <MenuBook />
                                        </IconButton>    
                                    </Link>
                                </CardActions>
                            </Card>
                        })
                    }
                </div>
                <div className="restaurantCategoryTitle">
                    Blocked Restaurants
                </div>
                <div className="restaurantCards">
                    {
                        restaurants.filter(res => res.block === '1').map(restaurant => {
                            return <Card key={restaurant.id} className="restaurantCard">
                                <CardHeader
                                    avatar={
                                        <Avatar 
                                            alt={restaurant.name}
                                            src={restaurant.logo}
                                        />
                                    }
                                    title = {restaurant.name}
                                    subheader={restaurant.description}
                                    action = {
                                        <Switch onChange={(event) => updateRestaurantStatus(event, restaurant.id)} color='primary' checked={restaurant.block === '0'} />
                                    }
                                />
                                <CardMedia 
                                    image = {restaurant.cover + '#image/png'}
                                    className = "restaurantCardImage"
                                />
                                <CardActions className="restaurantCardAction">
                                    <RestaurantForm restaurant={restaurant} onSubmit={values => updateRestaurant(values, restaurant.id)} onDelete={() => deleteRestaurant(restaurant.id)} />
                                    <TimeTable title={restaurant.name + ' Schedule'}>
                                        <TimeTableForm id={restaurant.id} />
                                    </TimeTable>
                                    <Link to={"/menu/" + restaurant.id} style = {{textDecoration: 'none', color: 'inherit'}}>
                                        <IconButton>
                                            <MenuBook />
                                        </IconButton>    
                                    </Link>
                                </CardActions>
                            </Card>
                        })
                    }
                </div>
                <div className="restaurantButton">
                    <RestaurantForm onSubmit={addRestaurant} />
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={spin}
                    autoHideDuration={2000}
                    message='Please wait...'
                />
            </div> 
        )
    }
}

export default RestaurantList