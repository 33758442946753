import './chart.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function Chart({title, data, dataKey, grid}) {

    return (
        <div className="chart">
            <h3 className="chartTitle">{title}</h3>
            <ResponsiveContainer width="100%" aspect={4/1}>
                <LineChart data={data}>
                    {grid && <CartesianGrid strole="#e0dfdf" strokeDasharray="5 5"/>}
                    <XAxis dataKey="date" stroke="#cb202d"/>
                    <YAxis type="number" domain={[0, 5000]} />
                    <Line type="monotone" dataKey={dataKey} stroke="#cb202d" />
                    <Tooltip/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}
