import React, { Component } from 'react'
import './district.css'
import axios from 'axios'
import * as config from '../../config'
import Map from '../../components/map/Map'
import { DataGrid } from '@material-ui/data-grid'
import { Delete } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import PopUp from '../../components/popup/PopUp'
import DistrictForm from '../../components/form/DistrictForm'

class District extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            districts: [],
            loading: false
        }
    }
    
    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'getAllDistricts', {})
        .then(response => {
            response.data.map(info => {
                info.action = info.id
                return null
            })
            this.setState({districts: response.data, loading: false})
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    render() {
        const { districts, loading } = this.state
        
        const columns = [
            { field: 'id',
              headerName: 'ID',
              type: 'number',
              width: 100,
              editable: false
            },
            {
              field: 'name',
              headerName: 'Name',
              width: 180,
              editable: true,
            },
            {
              field: 'lat',
              headerName: 'Latitude',
              width: 160,
              editable: true,
            },
            {
                field: 'lng',
                headerName: 'Longtitude',
                width: 160,
                editable: true,
            },
            {
              field: 'radius',
              headerName: 'Radius (m)',
              width: 160,
              type: 'number',
              editable: true,
            },
            {
                field: 'action',
                headerName: 'Action',
                width: 150,
                editable: false,
                renderCell: (param) => {
                    return (
                        <div>
                            {
                                <IconButton onClick={() => { deleteDistrict(param.value) }} aria-label="delete">
                                    <Delete color="primary" />
                                </IconButton>
                            }
                        </div>
                    )
                },
                headerAlign: 'center',
                align: 'center',
                sortable: false,
                filterable: false
            }
        ];

        const deleteDistrict = (district_id) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'deleteDistrict', {id: district_id})
            .then(response => {
                response.data.map(info => {
                    info.action = info.id
                    return null
                })
                this.setState({districts: response.data, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const editDistrict = ({ id, field, value }) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'updateDistrict', {id: id, field: field, value: value})
            .then(response => {
                response.data.map(info => {
                    info.action = info.id
                    return null
                })
                this.setState({districts: response.data, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const addDistrict = (values) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'addDistrict', values)
            .then(response => {
                response.data.map(info => {
                    info.action = info.id
                    return null
                })
                this.setState({districts: response.data, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        return (
            loading ? 
            <div className="districtLoading">Loading...</div> :
            <div className="district">
                <Map
                    center={{ lat: 22.33, lng: 114.10 }}
                    zoom={11}
                    places={districts}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDorlHcERdLoppRBvGW_ff81fs_i3Xq-IA"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <div className="districtButton">
                    <PopUp title="Add New District" action="ADD NEW DISTRICT">
                        <DistrictForm onSubmit={addDistrict} />
                    </PopUp>
                </div>
                <DataGrid
                    rows={districts} 
                    columns={columns} 
                    autoHeight={true}
                    onCellEditCommit={editDistrict}
                    disableSelectionOnClick
                />
            </div>
        )
    }
}

export default District

