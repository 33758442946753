import React, { Component } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './kitchen.css'
import axios from 'axios'
import * as config from '../../config'
import PopUp from '../../components/popup/PopUp'
import KitchenForm from '../../components/form/KitchenForm'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

class Kitchen extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            kitchens: [],
            loading: false
        }
    }
    
    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showAllKitchens', {})
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let kitchen = {
                        id: info.Kitchen.id,
                        name: info.Kitchen.name,
                        district: info.District.name,
                        address: info.Kitchen.address,
                        phone: info.Kitchen.phone,
                        device: info.Kitchen.device,
                        action: info.Kitchen.id
                    }
                    temp.push(kitchen)
                    return null
                })
                this.setState({kitchens: temp, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
    }
    
    render() {
        const { kitchens, loading } = this.state
        const columns = [
            { field: 'id',
              headerName: 'ID',
              type: 'number',
              width: 95,
              editable: false
            },
            {
              field: 'name',
              headerName: 'Name',
              width: 150,
              editable: true,
            },
            {
              field: 'district',
              headerName: 'District',
              width: 150,
              editable: false,
              sortable: false,
              filterable: false
            },
            {
                field: 'address',
                headerName: 'Address',
                width: 260,
                editable: true,
                sortable: false,
                filterable: false
            },
            {
              field: 'phone',
              headerName: 'Phone',
              width: 130,
              editable: true,
              sortable: false,
              filterable: false
            },
            {
                field: 'device',
                headerName: 'Device ID',
                width: 130,
                editable: true,
                sortable: false,
                filterable: false
            },
            {
                field: 'action',
                headerName: 'Action',
                width: 130,
                editable: false,
                renderCell: (param) => {
                    return (
                        <div>
                            {
                                <IconButton onClick={() => { deleteKitchen(param.value) }} aria-label="delete">
                                    <Delete color="primary" />
                                </IconButton>
                            }
                        </div>
                    )
                },
                headerAlign: 'center',
                align: 'center',
                sortable: false,
                filterable: false
            }
        ];

        const addKitchen = (values) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'addKitchen', values)
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let kitchen = {
                        id: info.Kitchen.id,
                        name: info.Kitchen.name,
                        district: info.District.name,
                        address: info.Kitchen.address,
                        phone: info.Kitchen.phone,
                        device: info.Kitchen.device
                    }
                    temp.push(kitchen)
                    return null
                })
                this.setState({kitchens: temp, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteKitchen = (kitchen_id) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'deleteKitchen', {id: kitchen_id})
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let kitchen = {
                        id: info.Kitchen.id,
                        name: info.Kitchen.name,
                        district: info.District.name,
                        address: info.Kitchen.address,
                        phone: info.Kitchen.phone,
                        device: info.Kitchen.device
                    }
                    temp.push(kitchen)
                    return null
                })
                this.setState({kitchens: temp, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const editKitchen = ({ id, field, value }) => {
            this.setState({loading: true})
            axios.post(config.BASE_URL + 'updateKitchen', {id: id, field: field, value: value})
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let kitchen = {
                        id: info.Kitchen.id,
                        name: info.Kitchen.name,
                        district: info.District.name,
                        address: info.Kitchen.address,
                        phone: info.Kitchen.phone,
                        device: info.Kitchen.device
                    }
                    temp.push(kitchen)
                    return null
                })
                this.setState({kitchens: temp, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
        }
        
        return (
            loading ? 
            <div className="kitchenLoading">Loading...</div> :
            <div className="kitchen">
                <div className="kitchenButton">
                    <PopUp title="Add New Kitchen" action="ADD NEW KITCHEN">
                        <KitchenForm onSubmit={addKitchen} />
                    </PopUp>
                </div>
                <DataGrid rows={kitchens} columns={columns} autoHeight={true} onCellEditCommit={editKitchen} disableSelectionOnClick/>
            </div> 
        )
    }
}

export default Kitchen
