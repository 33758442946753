import './sidebar.css'
import { LineStyle, PermIdentity, Timeline, Storefront, Receipt, MailOutline, PowerSettingsNew, Restaurant, ExploreOutlined} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import useToken from '../function/UseToken'

export default function Sidebar() {

    const { setToken } = useToken()

    return (
        <div className="sidebar">
           <div className="sidebarWrapper">
               <div className="sidebarMenu">
                   <h3 className="sidebarTitle">Dashboard</h3>
                   <ul className="sidebarList">
                       <Link to="/" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <LineStyle className="sidebarIcon" />
                                Home
                            </li>
                       </Link>
                       <Link to="/analytics" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <Timeline className="sidebarIcon" />
                                Analytics
                            </li>
                       </Link>
                       <Link to="/orders" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <Receipt className="sidebarIcon" />
                                Orders
                            </li>
                       </Link>
                   </ul>
               </div>
               <div className="sidebarMenu">
                   <h3 className="sidebarTitle">Manage</h3>
                   <ul className="sidebarList">
                        <Link to="/restaurants" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <Restaurant className="sidebarIcon" />
                                Restaurants
                            </li>
                       </Link>
                       <Link to="/kitchens" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <Storefront className="sidebarIcon" />
                                Kitchens
                            </li>
                       </Link>
                       <Link to="/districts" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <ExploreOutlined className="sidebarIcon" />
                                Districts
                            </li>
                       </Link>
                   </ul>
               </div>
               <div className="sidebarMenu">
                   <h3 className="sidebarTitle">Quick Menu</h3>
                   <ul className="sidebarList">
                        <Link to="/users" style = {{textDecoration: 'none', color: 'inherit'}}>
                            <li className="sidebarListItem">
                                <PermIdentity className="sidebarIcon" />
                                Users
                            </li>
                        </Link>
                        <li className="sidebarListItem">
                           <MailOutline className="sidebarIcon" />
                           Subscriptions
                       </li>
                   </ul>
               </div>
               <div className="sidebarMenu">
                   <h3 className="sidebarTitle">Other</h3>
                   <ul className="sidebarList">
                       <li className="sidebarListItem" onClick={() => setToken(null)}>
                           <PowerSettingsNew className="sidebarIcon" />
                           Log Out
                       </li>
                   </ul>
               </div>
           </div>
        </div>
    )
}
