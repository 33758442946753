import React from 'react'
import { Dialog, DialogContent, Grid, Paper, TextField, Button } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import * as config from '../../config'

export default function LoginForm(props) {

    const initialValues = {
        email: '',
        password: ''
    }
      
    const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email.").required("Required"),
    password: Yup.string().min(6, "It's too short").required("Required")
    })

    const handleSubmit = (values) => {
        axios.post(config.BASE_URL + 'adminLogin', values)
        .then(response => {
            console.log(response.data)
            if(response.data.code === 200){
                props.onSubmit(response.data.token)
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    return(
        <Dialog open={true}>
            <DialogContent>
                <Grid>
                    <Paper elevation={0} style={{width: 300}}>
                        <div className="formMedia" onClick={() => window.open('https://orderonline.hk', '_self')}>
                            <img className="formLogo" alt="" src="https://admin.orderonline.hk/mobileapp_api/app/webroot/uploads/logo.png"></img>
                        </div>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
                            {(props) => (
                                <Form noValidate className='form'>
                                    <Field as={TextField} name='email' label='Email' fullWidth
                                        error={props.errors.email && props.touched.email}
                                        helperText={<ErrorMessage name='email' />} required />
                                    <Field as={TextField} name='password' label='Password' type='password' fullWidth
                                        error={props.errors.password && props.touched.password}
                                        helperText={<ErrorMessage name='password' />} required />
                                    <div className="submitContainer">
                                        <Button className='submitButton' type='submit' variant='contained'color='primary'>Log In</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}