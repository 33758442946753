import React from 'react'
import { Grid, Paper, Button, Snackbar} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const DistrictForm = (props) => {
    const [open, setOpen ] = React.useState(false);
    const paperStyle = { padding: '0 15px 10px 15px', width: 300 }
    const btnStyle = { margin: '15px 50px 0' , width: 200 }

    const initialValues = {
        name: '',
        lat: '',
        lng: '',
        radius: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, "It's too short").required("Required"),
        lat: Yup.number().min(-90, "Enter a valid lantitude").max(90, "Enter a valid latitude").required("Required"),
        lng: Yup.number().min(-180, "Enter a valid lantitude").max(180, "Enter a valid latitude").required("Required"),
        radius: Yup.number().required("Required")
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <Grid>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    Use the <a href="https://www.mapdevelopers.com/draw-circle-tool.php" target="_blank" rel="noreferrer">online tool</a> to get the data.
                </Grid>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
                    {(props) => (
                        <Form noValidate>

                            <Field as={TextField} name='name' label='Name' fullWidth
                                error={props.errors.name && props.touched.name}
                                helperText={<ErrorMessage name='name' />} required />

                            <Field as={TextField} name='lat' label='Latitude' fullWidth
                                error={props.errors.lat && props.touched.lat}
                                helperText={<ErrorMessage name='lat' />} required />

                            <Field as={TextField} name="lng" label='Longtitude' fullWidth
                                error={props.errors.lng && props.touched.lng}
                                helperText={<ErrorMessage name='lng' />} required />

                            <Field as={TextField} name='radius' label='Radius' fullWidth
                                error={props.errors.radius && props.touched.radius}
                                helperText={<ErrorMessage name='radius' />} required />

                            <Button type='submit' style={btnStyle} variant='contained'color='primary'>Add</Button>
                        </Form>
                    )}
                </Formik>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Added successfully. Refresh the page."
            />
        </Grid>
    )
}

export default DistrictForm