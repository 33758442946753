import React, { Component } from 'react'
import './restaurantMenu.css'
import axios from 'axios'
import * as config from '../../config'
import { Card, CardHeader, Button, CardContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, Switch, TextField, Accordion, AccordionSummary, AccordionDetails, Chip, Snackbar, Breadcrumbs, IconButton, Divider, FormControlLabel } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import ExtraItemForm from '../../components/form/ExtraItemForm'
import MenuItemForm from '../../components/form/MenuItemForm'
import { Link } from 'react-router-dom'

class RestaurantMenu extends Component{

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            spin: false,
            alert: false,
            menu_list: [],
            name: ''
        }
    }

    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showRestaurantMenu', { restaurant_id: this.props.match.params.restaurant_id})
        .then(response => {
            let temp_menu = []
            response.data.menus.map(info => {
                let menu_items = []
                info.RestaurantMenuItem.map(item => {
                    let extra = []
                    item.RestaurantMenuExtraSection.map(section => {
                        let items = []
                        section.RestaurantMenuExtraItem.map(e => {
                            let extra_item = {
                                id: e.id,
                                name: e.name,
                                price: e.price
                            }
                            items.push(extra_item)
                            return null
                        })
                        let extra_section = {
                            id: section.id,
                            name: section.name,
                            active: section.active,
                            required: section.required,
                            extra_items: items
                        }
                        extra.push(extra_section)
                        return null
                    })
                    let menu_item = {
                        id: item.id,
                        name: item.name,
                        translation: item.description,
                        price: item.price,
                        image: config.IMAGE_URL + item.image,
                        active: item.active,
                        old_price: item.old_price,
                        additional: extra
                    }
                    menu_items.push(menu_item)
                    return null
                })
                let menu = {
                    id: info.RestaurantMenu.id,
                    name: info.RestaurantMenu.name,
                    active: info.RestaurantMenu.active,
                    items: menu_items
                }
                temp_menu.push(menu)
                return null
            })
            this.setState({menu_list: temp_menu, name: response.data.name, loading: false})
        })
        .catch(error => {
            console.log(error)
        })
    }

    render(){

        const { menu_list, loading, spin, alert, name } = this.state

        const closeSnackbar = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({alert: false})
        };

        const addMenu = () => {
            this.setState({spin: true})
            let data = {
                name: 'New Menu',
                restaurant_id: this.props.match.params.restaurant_id,
                active: '0'
            }
            axios.post(config.BASE_URL + 'addRestaurantMenu', data)
            .then(response => {
                if(response.data.code === 200){
                    let menu = {
                        id: response.data.id,
                        name: data.name,
                        active: data.active,
                        items: []
                    }
                    this.setState({ menu_list: [...menu_list, menu] })
                }else{
                    console.log(response.data.msg)
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const handleChangeMenuName = (event, index) => {
            let new_list = [...menu_list]
            new_list[index].name = event.target.value
            this.setState({menu_list: new_list})
        }

        const updateMenuName = (event, id) => {
            if(event.keyCode === 13){
                this.setState({spin: true})
                let data = {
                    id: id,
                    name: event.target.value
                }
                axios.post(config.BASE_URL + 'changeRestaurantMenuName', data)
                .then(response => {
                    this.setState({spin: false})
                    if(response.data.code === 200){
                        this.setState({alert: true})
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }

        const handleMenuStatusChange = (event, id) => {
            this.setState({spin: true})
            let data = {
                id: id,
                active: event.target.checked ? '1' : '0'
            }
            axios.post(config.BASE_URL + 'changeRestaurantMenuStatus', data)
            .then(response => {
                this.setState({spin: false})
                if(response.data.code === 200){
                    this.setState({menu_list: menu_list.map(menu => (menu.id === id ? {...menu, ...data} : menu ))})
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteMenu = (id) => {
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'deleteRestaurantMenu', {menu_id: id})
            .then(response => {
                if(response.data.code === 200){
                    this.setState({ menu_list: menu_list.filter(menu => menu.id !== id)})
                } else {
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const addMenuItem = (values, id, m_id) => {

            this.setState({spin: true})
            const data = new FormData()
            data.append('restaurant_menu_id', id)
            data.append('name', values.name)
            data.append('description', values.description)
            data.append('price', values.price)
            if(values.old_price !== '') data.append('old_price', values.old_price)
            data.append('restaurant_id', this.props.match.params.restaurant_id)
            if(values.file != null) data.append('image', values.file, values.file.name)

            axios.post(config.BASE_URL + 'addRestaurantMenuItem', data)
            .then(response => {
                if(response.data.code === 200){
                    let new_item = {
                        id: response.data.id,
                        name: values.name,
                        translation: values.description,
                        price: values.price,
                        active: '1',
                        old_price: values.old_price === '' ? null : values.old_price,
                        additional: []
                    }
                    response.data.image ? new_item.image = config.IMAGE_URL + response.data.image : new_item.image = ''
                    let new_list = [...menu_list]
                    let items = [...new_list[m_id].items, new_item]
                    new_list[m_id].items = items
                    this.setState({menu_list: new_list})
                }else{
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const updateMenuItem = (values, id, m_id) => {

            this.setState({spin: true})
            const data = new FormData()
            data.append('id', id)
            data.append('name', values.name)
            data.append('description', values.description)
            data.append('price', values.price)
            if (values.old_price !== '') data.append('old_price', values.old_price)
            data.append('restaurant_id', this.props.match.params.restaurant_id)
            if(values.file != null) data.append('image', values.file, values.file.name)

            axios.post(config.BASE_URL + 'updateRestaurantMenuItem', data)
            .then(response => {
                if(response.data.code === 200){
                    let info = {
                        name: values.name,
                        translation: values.description,
                        price: values.price,
                        old_price: values.old_price === '' ? null : values.old_price
                    }
                    if(response.data.image) info.image = config.IMAGE_URL + response.data.image
                    let new_list = [...menu_list]
                    let items = new_list[m_id].items.map(item => (item.id === id ? {...item, ...info} : item ))
                    new_list[m_id].items = items
                    this.setState({menu_list: new_list})
                }else{
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const updateMenuItemStatus = (event, id, m_id) => {
            this.setState({spin: true})
            let data = {
                id: id,
                active: event.target.checked ? '1' : '0'
            }
            axios.post(config.BASE_URL + 'updateRestaurantMenuItemStatus', data)
            .then(response => {
                if(response.data.code === 200){
                    let new_list = [...menu_list]
                    let items = new_list[m_id].items.map(item => (item.id === id ? {...item, ...data} : item ))
                    new_list[m_id].items = items
                    this.setState({menu_list: new_list})
                }else{
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteMenuItem = (id, m_id) => {
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'deleteRestaurantMenuItem', {menu_item_id: id})
            .then(response => {
                if(response.data.code === 200){
                    let items = menu_list[m_id].items.filter(item => item.id !== id)
                    let new_list = [...menu_list]
                    new_list[m_id].items = items
                    this.setState({ menu_list: new_list })
                } else {
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const addExtraSection = (item_id, m_id, i_id) => {
            this.setState({spin: true})
            let data = {
                name: 'Options',
                restaurant_menu_item_id: item_id,
                required: '0',
                active: '0'
            }
            axios.post(config.BASE_URL + 'addRestaurantMenuExtraSection', data)
            .then(response => {
                if(response.data.code === 200){
                    let extra_section = {
                        id: response.data.id,
                        name: data.name,
                        active: data.active,
                        required: data.required,
                        extra_items: []
                    }
                    let extra_sections = [...menu_list[m_id].items[i_id].additional, extra_section]
                    let new_list = [...menu_list]
                    new_list[m_id].items[i_id].additional = extra_sections
                    this.setState({ menu_list: new_list })
                } else {
                    console.log(response.data.msg)
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const handleChangeSectionName = (event, m_id, i_id, e_id) => {
            let new_list = [...menu_list]
            new_list[m_id].items[i_id].additional[e_id].name = event.target.value
            this.setState({menu_list: new_list})
        }

        const updateExtraSectionName = (event, id) => {
            if(event.keyCode === 13){
                this.setState({spin: true})
                let data = {
                    extra_section_id: id,
                    name: event.target.value
                }
                axios.post(config.BASE_URL + 'renameRestaurantMenuExtraSection', data)
                .then(response => {
                    this.setState({spin: false})
                    if(response.data.code === 200){
                        this.setState({alert: true})
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }

        const changeExtraSection = (event, id, m_id, i_id) => {
            this.setState({spin: true})
            let data = {
                id: id,
                required: event.target.checked ? '1' : '0'
            }
            axios.post(config.BASE_URL + 'updateRestaurantMenuExtraSection', data)
            .then(response => {
                this.setState({spin: false})
                if(response.data.code === 200){
                    let new_list = [...menu_list]
                    let extra_sections = new_list[m_id].items[i_id].additional.map(extra => (extra.id === id ? {...extra, ...data} : extra ))
                    new_list[m_id].items[i_id].additional = extra_sections
                    this.setState({menu_list: new_list})
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                console.log(error)
            })
        }

        const publishExtraSection = (event, id, m_id, i_id) => {
            this.setState({spin: true})
            let data = {
                id: id,
                active: event.target.checked ? '1' : '0'
            }
            axios.post(config.BASE_URL + 'updateRestaurantMenuExtraSectionStatus', data)
            .then(response => {
                this.setState({spin: false})
                if(response.data.code === 200){
                    let new_list = [...menu_list]
                    let extra_sections = new_list[m_id].items[i_id].additional.map(extra => (extra.id === id ? {...extra, ...data} : extra ))
                    new_list[m_id].items[i_id].additional = extra_sections
                    this.setState({menu_list: new_list})
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteExtraSection = (extra_id, m_id, i_id) => {
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'deleteRestaurantMenuExtraSection', {extra_section_id: extra_id})
            .then(response => {
                if(response.data.code === 200){
                    let extra_sections = menu_list[m_id].items[i_id].additional.filter(extra => extra.id !== extra_id)
                    let new_list = [...menu_list]
                    new_list[m_id].items[i_id].additional = extra_sections
                    this.setState({ menu_list: new_list })
                } else {
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const addExtraItem = (values, id, s_id, i_id, m_id) => {
            values.restaurant_menu_extra_section_id = id
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'addRestaurantMenuExtraItem', values)
            .then(response => {
                if(response.data.code === 200){
                    let extra_item = {
                        id: response.data.id,
                        name: values.name,
                        price: values.price
                    }
                    let extra_items = [...menu_list[m_id].items[i_id].additional[s_id].extra_items, extra_item]
                    let new_list = [...menu_list]
                    new_list[m_id].items[i_id].additional[s_id].extra_items = extra_items
                    this.setState({ menu_list: new_list })
                }else{
                    console.log(response.data.msg)
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        const deleteExtraItem = (e_id, s_id, i_id, m_id) => {
            this.setState({spin: true})
            axios.post(config.BASE_URL + 'deleteRestaurantMenuExtraItem', {extra_item_id: e_id})
            .then(response => {
                if(response.data.code === 200){
                    let extra_items = menu_list[m_id].items[i_id].additional[s_id].extra_items.filter(e => e.id !== e_id)
                    let new_list = [...menu_list]
                    new_list[m_id].items[i_id].additional[s_id].extra_items = extra_items
                    this.setState({ menu_list: new_list })
                } else {
                    console.log(response.data.msg);
                }
                this.setState({spin: false})
            })
            .catch(error => {
                console.log(error)
            })
        }

        return (
            loading ?
            <div className="restaurantListLoading">Loading...</div>:
            <div className="restaurantMenu">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" to="/restaurants" style = {{textDecoration: 'none', color: 'inherit'}}>
                        Restaurants
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to={'/menu/' + this.props.match.params.restaurant_id}
                        aria-current="page"
                        style = {{textDecoration: 'none'}}
                        >
                        {name}
                    </Link>
                </Breadcrumbs>
                <div className="menuCards">
                    {
                        menu_list.map((menu, menu_index) => {
                            return <Card key={menu.id} className="menuCard">
                                <CardHeader
                                    title = {
                                        <TextField
                                            id="outlined"
                                            value={menu.name}
                                            helperText="*Note: press Enter to save."
                                            onKeyDown={event => updateMenuName(event, menu.id)}
                                            onChange={event=> handleChangeMenuName(event, menu_index)}
                                            fullWidth
                                        />
                                    }
                                    action = {
                                        <Switch onChange={(event) => handleMenuStatusChange(event, menu.id)} color='primary' checked={menu.active==='1'} />
                                    }
                                />
                                <CardContent>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {
                                        menu.items.map((item, item_index) => {
                                            return <Accordion key={item.id}>
                                                <AccordionSummary>
                                                    <ListItem className='itemInfoContainer'>
                                                        <ListItemAvatar className='itemImageContainer'>
                                                            <Avatar alt="Item Image" src={item.image} className='itemImage' />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={item.name}
                                                            secondary={item.translation}
                                                        />
                                                    </ListItem>
                                                    <div className="itemInfoExtra">
                                                        <Switch className='itemInfoAction' onChange={(event) => updateMenuItemStatus(event, item.id, menu_index)} color='primary' checked={item.active==='1'} />
                                                        <div className="itemInfoPrice">
                                                            {'$' + item.price}
                                                        </div>
                                                        {
                                                            item.old_price ? <div className="itemInfoDiscount"><strike>{'$' + item.old_price}</strike></div> : null
                                                        }
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='itemDetails'>
                                                        {
                                                            item.additional.map((extra, extra_index) => {
                                                                return <div key={extra.id}>
                                                                    <div className="extraSection">
                                                                        <div className="extraSectionTitle">
                                                                            <TextField
                                                                                value={extra.name}
                                                                                onKeyDown={event => updateExtraSectionName(event, extra.id)}
                                                                                onChange={event=> handleChangeSectionName(event, menu_index, item_index, extra_index)}
                                                                                fullWidth
                                                                            />
                                                                        </div>
                                                                        <div className="extraSectionSettings">
                                                                            <FormControlLabel
                                                                                control={<Switch color='primary' onChange={(event) => publishExtraSection(event, extra.id, menu_index, item_index, extra_index)} size='small' checked={extra.active==='1'} />}
                                                                                label={<div className="actionLabel">Visible</div>}
                                                                                labelPlacement='start'
                                                                            />
                                                                            <FormControlLabel
                                                                                control={<Switch onChange={(event) => changeExtraSection(event, extra.id, menu_index, item_index)} size='small' checked={extra.required==='1'} />}
                                                                                label={<div className="actionLabel">Required</div>}
                                                                                labelPlacement="start"
                                                                            />
                                                                        </div>
                                                                        <div className="extraSectionAction">
                                                                            <IconButton className='actionButton' onClick={() => deleteExtraSection(extra.id, menu_index, item_index)}>
                                                                                <Delete color='primary' />
                                                                            </IconButton>
                                                                        </div>
                                                                    </div>
                                                                    <Divider />
                                                                    <List>
                                                                        {
                                                                            extra.extra_items.map((e) => {
                                                                                return <Chip
                                                                                    className='extraItemChip'
                                                                                    label={e.name + ' +$' + e.price}
                                                                                    key={e.id}
                                                                                    variant='outlined'
                                                                                    color='primary'
                                                                                    onDelete={() => deleteExtraItem(e.id, extra_index, item_index, menu_index)}
                                                                                /> 
                                                                            })
                                                                        }
                                                                        <ExtraItemForm onSubmit={values => addExtraItem(values, extra.id, extra_index, item_index, menu_index)} />
                                                                    </List>
                                                                </div>
                                                            })
                                                        }
                                                        <div className='buttonsContainer'>
                                                            <Button  color="primary" onClick={() => addExtraSection(item.id, menu_index, item_index)}>
                                                                <Add /> Add Section
                                                            </Button>
                                                            <MenuItemForm onSubmit={values => updateMenuItem(values, item.id, menu_index)} item={item} />
                                                            <Button  color="primary" onClick={() => deleteMenuItem(item.id, menu_index)}>
                                                                <Delete /> Delete Item
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        })
                                    }
                                    </List>
                                    <div className="buttonsContainer">
                                        <MenuItemForm onSubmit={values => addMenuItem(values, menu.id, menu_index)} />
                                        <Button color="secondary" onClick={() => deleteMenu(menu.id)}>
                                            <Delete /> Delete Menu
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        })
                    }
                </div>
                <div className="menuButton">
                    <Button variant="outlined" color="primary" onClick={addMenu}>
                        <Add /> Add Menu
                    </Button>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={spin}
                    autoHideDuration={2000}
                    message='Please wait...'
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} 
                    open={alert} 
                    onClose={closeSnackbar}
                    autoHideDuration={2000} 
                    message='Saved Successfully!'
                />
            </div>
        )
    }
}
export default RestaurantMenu
