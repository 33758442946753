import './featuredInfo.css'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

export default function FeaturedInfo({current, previous, option}) {
    return (
        <div className="featured">
            <div className="featuredItem">
                <span className="featuredTitle">Registered Users</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">{current.user_count}</span>
                    {
                        previous != null ?
                        <span className="featuredMoneyRate">
                            {calculateDifference(current.user_count, previous.user_count)}
                            {
                                (current.user_count - previous.user_count) >= 0 ?
                                <ArrowUpward className="featuredIcon"/> :
                                <ArrowDownward className="featuredIcon negative"/>
                            }
                        </span> : 
                        <span className="featuredMoneyRate">
                            <ArrowUpward className="featuredIcon"/>
                        </span>
                    }
                </div>
                {
                    option ? <span className="featuredSub">Compared to last {option}</span> : <span className="featuredSub">Since the beginning</span>
                }
            </div>
            <div className="featuredItem">
                <span className="featuredTitle">Total Earnings</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">${current.total_earnings}</span>
                    {
                        previous != null ?
                        <span className="featuredMoneyRate">
                            {calculateDifference(current.total_earnings, previous.total_earnings)}
                            {
                                (current.total_earnings - previous.total_earnings) >= 0 ?
                                <ArrowUpward className="featuredIcon"/> :
                                <ArrowDownward className="featuredIcon negative"/>
                            }
                        </span> :
                        <span className="featuredMoneyRate">
                            <ArrowUpward className="featuredIcon"/>
                        </span>
                    }
                </div>
                {
                    option ? <span className="featuredSub">Compared to last {option}</span> : <span className="featuredSub">Since the beginning</span>
                }
            </div>
            <div className="featuredItem">
                <span className="featuredTitle">Donations</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">${current.total_donations}</span>
                    {
                        previous != null ?
                        <span className="featuredMoneyRate">
                            {calculateDifference(current.total_donations, previous.total_donations)}
                            {
                                (current.total_donations - previous.total_donations) >= 0 ?
                                <ArrowUpward className="featuredIcon"/> :
                                <ArrowDownward className="featuredIcon negative"/>
                            }
                        </span> :
                        <span className="featuredMoneyRate">
                            <ArrowUpward className="featuredIcon"/>
                        </span>
                    }
                </div>
                {
                    option ? <span className="featuredSub">Compared to last {option}</span> : <span className="featuredSub">Since the beginning</span>
                }
            </div>
        </div>
    )
}

function calculateDifference(new_data, old_data){
    var res = (new_data - old_data) / new_data * 100
    if (res >= 0) {
        return '+' + Math.round(res * 100) / 100
    } else {
        return Math.round(res * 100) / 100
    }
}
