import React, { Component } from 'react'
import './analytics.css'
import FeaturedInfo from '../../components/featuredInfo/FeaturedInfo'
import Map from '../../components/map/Map'
import axios from 'axios'
import * as config from '../../config'

export class Analytics extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            addresses: [],
            current: {},
            loading: false
        }
    }
    
    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showAnalyticsData', {})
        .then(response => {
            this.setState({
                addresses: response.data.addresses,
                current: response.data.current,
                loading: false
            })
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    render() {
        const { addresses, loading, current } = this.state

        return (
            loading ? 
            <div className="analyticsLoading">Loading...</div> :
            <div className="analytics">
                <FeaturedInfo current={current} />
                <div className="analyticsMapTitle">Ordered Addresses</div>
                <div className="analyticsMap">
                    <Map
                        center={{ lat: 22.33, lng: 114.10 }}
                        zoom={11}
                        places={addresses}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDorlHcERdLoppRBvGW_ff81fs_i3Xq-IA"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
            </div>
        )
    }
}

export default Analytics
