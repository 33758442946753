import React from 'react'
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Chip } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function PopUp({children, title, action}) {

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getComponent = (action) => {
      switch(action){
        case 'EDIT_ICON':
          return <IconButton aria-label="Edit Restaurant" onClick={handleClickOpen} >
              <Edit />
          </IconButton>
        case 'CHIP':
          return <Chip
              label={title}
              variant='outlined'
              color='secondary'
              icon={
                  <Add />
              }
              onClick={handleClickOpen}
              style={{margin: '5px'}}
          />
        default:
          return <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            <Add /> {action}
        </Button>
      }
    }

    return(
      <div>
        {getComponent(action)}
        <Dialog open={open}>
            <DialogTitle onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
      </div>
    )
}
