import React, { useRef } from 'react'
import { Grid, Paper, Button, IconButton, TextField, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { Add, Close, Edit, PhotoCamera } from '@material-ui/icons'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import './form.css'

const MenuItemForm = (props) => {

    const inputRef = useRef(null)

    const initialValues = {
        name: props.item ? props.item.name : '',
        description: props.item ? props.item.translation : '',
        price: props.item ? props.item.price : '',
        old_price: props.item ? props.item.old_price ? props.item.old_price : '' : '',
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        price: Yup.number().required("Required"),
        old_price: Yup.number().nullable(true)
    })

    const [open, setOpen] = React.useState(false)
    const [image, setImage] = React.useState(props.item ? props.item.image : '')
    const [file, setFile] = React.useState(null)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSubmit = (values) => {
        values.file = file
        props.onSubmit(values)
        handleClose()
    }

    const imageSelectedHandler = event =>{
        setImage(URL.createObjectURL(event.target.files[0]))
        setFile(event.target.files[0])
    }

    return ([
        props.item ?
        <Button  key={'0'} onClick={handleClickOpen} color='secondary'>
            <Edit /> Edit Item
        </Button> : 
        <Button  key={'0'} color="primary" onClick={handleClickOpen}>
            <Add /> Add Menu Item
        </Button>
        ,
        <Dialog key={'1'} open={open} onClose={handleClose}>
            <DialogActions >
                <div className="formTitle">{props.item ? 'Edit Menu Item' : 'Add New Menu Item'}</div>
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogContent>
                <Grid>
                    <Paper elevation={0} style={{width: 300}}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
                            {(props) => (
                                <Form noValidate className='form'>
                                    <div className="itemPreview" >
                                        <img alt='Menu Item Cover' src={image} />
                                        <Button variant='outlined' className='previewButton' onClick={() => inputRef.current?.click()}><PhotoCamera/>Select Image</Button>
                                    </div>
                                    <input type='file' accept="image/png, image/jpeg" style={{display: 'none'}} onChange={imageSelectedHandler} ref={inputRef} />
                                    <Field as={TextField} name='name' label='Name' fullWidth
                                        error={props.errors.name && props.touched.name}
                                        helperText={<ErrorMessage name='name' />} required />
                                    <Field as={TextField} name='description' label='Translation' fullWidth
                                        error={props.errors.description && props.touched.description}
                                        helperText={<ErrorMessage name='description' />} required />
                                    <Field as={TextField} name='price' label='Price' fullWidth
                                        error={props.errors.price && props.touched.price}
                                        helperText={<ErrorMessage name='price' />} required />
                                    <Field as={TextField} name='old_price' label='Old Price' fullWidth
                                        error={props.errors.old_price && props.touched.old_price}
                                        helperText={<ErrorMessage name='old_price' />} />
                                    <div className='menuItemNote'>Add old price to show 'Discount' on the app.</div>
                                    <div className="buttonContainer">
                                        <Button className='submitButton' type='submit' variant='contained'color='primary'>Submit</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </DialogContent>
        </Dialog>
    ])
}

export default MenuItemForm