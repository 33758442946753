import React, { Component } from 'react'
import './orderList.css'
import axios from 'axios'
import { DataGrid } from '@material-ui/data-grid'
import * as config from '../../config'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import {DirectionsBike, LocalMallOutlined, CheckCircle, Schedule, OutdoorGrillOutlined, DepartureBoardOutlined, CancelOutlined } from '@material-ui/icons';

export class OrderList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            option: 'active',
            orders: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({loading: true})
        axios.post(config.BASE_URL + 'showAllOrders', { option: this.state.option })
            .then(response => {
                let temp = []
                response.data.msg.map(info => {
                    let order = {
                        id: info.Order.id,
                        name: info.UserInfo.first_name + ' ' + info.UserInfo.last_name,
                        district: info.Address.street ? info.Address.street.split(', ')[info.Address.street.split(', ').length - 2] : '',
                        restaurant: info.Restaurant.name,
                        total: info.Order.price,
                        type: info.Order.delivery,
                        time: info.Order.instructions,
                        status: info.Order.restaurant_status,
                        update_time: info.Order.actual_time
                    }
                    temp.push(order)
                    return null
                })
                this.setState({orders: temp, loading: false})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { option, orders, loading} = this.state

        const columns = [
            { field: 'id',
              headerName: 'ID',
              type: 'number'
            },
            {
              field: 'name',
              headerName: 'Name',
              editable: false,
              width: 125,
              sortable: false
            },
            {
              field: 'district',
              headerName: 'District',
              editable: false,
              sortable: false,
              width: 120
            },
            {
              field: 'restaurant',
              headerName: 'Restaurant',
              editable: false,
              sortable: false,
              width: 140
            },
            {
                field: 'total',
                headerName: 'Total',
                type: 'number',
                editable: false,
                sortable: false,
                width: 90
            },
            {
                field: 'type',
                headerName: 'Type',
                sortable: false,
                renderCell: (param) => {
                    return (
                        <div>
                            {
                                param.value === '1' ? <DirectionsBike className="orderListIcon" /> : <LocalMallOutlined className="orderListIcon"/>
                            }
                        </div>
                    )
                },
                width: 80,
            },
            {
                field: 'time',
                headerName: 'Scheduled Time',
                editable: false,
                sortable: false,
                width: 170
            },
            {
                field: 'status',
                headerName: 'Status',
                editable: false,
                sortable: false,
                width: 80,
                renderCell: (param) => {
                    return (
                        <div>
                            {
                                param.value === '0' ? <Schedule className="orderListIcon" /> : param.value === '1' ? <OutdoorGrillOutlined className="orderListIcon yellow" /> : param.value === '2' ? <DepartureBoardOutlined className="orderListIcon yellow"/> : param.value === '3' ? <CheckCircle className="orderListIcon green" /> : <CancelOutlined className="orderListIcon red"/>  
                            }
                        </div>
                    )
                }
            },
            {
                field: 'update_time',
                headerName: 'App',
                editable: false,
                sortable: false,
                width: 120
            }
        ];

        const handleSelection = (event, option) => {
            this.setState({option: option, loading: true});
            axios.post(config.BASE_URL + 'showAllOrders', {option: option})
                .then(response => {
                    let temp = []
                    response.data.msg.map(info => {
                        let order = {
                            id: info.Order.id,
                            name: info.UserInfo.first_name + ' ' + info.UserInfo.last_name,
                            district: info.Address.street ? info.Address.street.split(', ')[info.Address.street.split(', ').length - 2] : '',
                            restaurant: info.Restaurant.name,
                            total: info.Order.price,
                            type: info.Order.delivery,
                            time: info.Order.instructions,
                            status: info.Order.restaurant_status,
                            update_time: info.Order.actual_time
                        }
                        temp.push(order)
                        return null
                    })
                    this.setState({orders: temp, loading: false})
                })
                .catch(error => {
                    console.log(error)
                }
            )
        }

        return (
            <div className="orderList">
                <div className="orderListStatus">
                    <ToggleButtonGroup
                        value={option}
                        exclusive
                        onChange={handleSelection}
                        size="small"
                        >
                        <ToggleButton value="active">
                            Active
                        </ToggleButton>
                        <ToggleButton value="completed">
                            Completed
                        </ToggleButton>
                        <ToggleButton value="rejected">
                            Rejected
                        </ToggleButton>
                        <ToggleButton value="all">
                            All
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                {
                    loading ?
                    <div className="orderListLoading">Loading...</div> :
                    <DataGrid rows={orders} columns={columns} density="compact" autoHeight={true} disableSelectionOnClick/>
                }
            </div>
        )
    }
}

export default OrderList
