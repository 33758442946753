import './widgetSm.css'
import { Chip, Avatar } from '@material-ui/core'

export default function WidgetSm({data}) {
    return (
        <div className="widgetSm">
            <span className="widgetSmTitle">Top Selling Items</span>
            <div className="widgetSmList">
                {
                    data.map(item => {
                        return <Chip variant="outlined" size="small" label={item.name} avatar={<Avatar>{item.quantity}</Avatar>} />
                    })
                }
            </div>
        </div>
    )
}
