import React, { Component } from 'react'
import FeaturedInfo from '../../components/featuredInfo/FeaturedInfo'
import Chart from '../../components/chart/Chart'
import './home.css'
import WidgetSm from '../../components/widgetSm/WidgetSm'
import axios from 'axios'
import * as config from '../../config'
import PieWidget from '../../components/pieWidget/PieWidget'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

class Home extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            option: 'week',
            current: {},
            previous: {},
            order_stats: [],
            order_device: [],
            top_items: [],
        }
    }
    
    componentDidMount() {
        this.setState({loading: true});
        axios.post(config.BASE_URL + 'showDashboardData', {option: this.state.option})
            .then(response => {
                this.setState({
                    current: response.data.current,
                    previous: response.data.previous,
                    order_stats: response.data.order_stats,
                    order_device: response.data.order_device,
                    top_items: response.data.top_items,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
            }
        )
    }

    render(){
        const { loading, option, current, previous, order_stats, order_device, top_items} = this.state

        const handleSelection = (event, option) => {
            this.setState({loading: true, option: option});
            axios.post(config.BASE_URL + 'showDashboardData', {option: option})
                .then(response => {
                    this.setState({
                        current: response.data.current,
                        previous: response.data.previous,
                        order_stats: response.data.order_stats,
                        order_device: response.data.order_device,
                        top_items: response.data.top_items,
                        loading: false
                    })
                })
                .catch(error => {
                    console.log(error)
                }
            )
        }

        return (
            <div className="home">
                {
                    loading ? <div className="homeLoading">Loading...</div> :
                    <div>
                        <div className="homeSelection">
                            <ToggleButtonGroup
                                value={option}
                                exclusive
                                onChange={handleSelection}
                                size="small"
                                >
                                <ToggleButton value="week">
                                    7 Days
                                </ToggleButton>
                                <ToggleButton value="month">
                                    30 Days
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <FeaturedInfo current={current} previous={previous} option={option} />
                        <Chart data={order_stats} title="Daily Orders" grid dataKey="Total" />
                        <div className="homeWidgets">
                            <WidgetSm data={top_items} />
                            <PieWidget data={order_device} title="Order Devices" dataKey="value" />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Home


