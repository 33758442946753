import React, { useRef } from 'react'
import { Grid, Paper, Button, IconButton, TextField, Dialog, DialogContent, DialogActions, InputLabel, Select, MenuItem, FormControl, FormHelperText, Checkbox, FormControlLabel } from '@material-ui/core'
import { Add, Close, PhotoCamera, Settings } from '@material-ui/icons'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import * as config from '../../config'
import './form.css'

const RestaurantForm = (props) => {

    const [open, setOpen] = React.useState(false)
    const [kitchens, setKitchens] = React.useState([]);
    const [banner, setBanner] = React.useState(props.restaurant ? props.restaurant.banner : '')
    const [bannerFile, setBannerFile] = React.useState(null)

    const [logo, setLogo] = React.useState(props.restaurant ? props.restaurant.logo : '')
    const [logoFile, setLogoFile] = React.useState(null)

    const [cover, setCover] = React.useState(props.restaurant ? props.restaurant.cover : '')
    const [coverFile, setCoverFile] = React.useState(null)

    const rowStyle = { display: 'flex', flexDirection: 'row', margin: '5px 0'}
    const fieldStyle = { margin: '0 10px'}

    const bannerRef = useRef(null)
    const logoRef = useRef(null)
    const coverRef = useRef(null)

    const initialValues = {
        name: props.restaurant ? props.restaurant.name : '',
        slogan: props.restaurant ? props.restaurant.description : '',
        email: props.restaurant ? props.restaurant.email : '',
        kitchen: props.restaurant ? props.restaurant.kitchen : '',
        self_pickup: props.restaurant ? props.restaurant.self_pickup : false,
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, "It's too short").required("Required"),
        slogan: Yup.string().min(5, "It's too short").required("Required"),
        email: Yup.string().email("Enter a valid email address").required("Required"),
        kitchen: Yup.string().min(1, "Select the kitchen").required("Required")
    })

    const handleClickOpen = () => {
        setOpen(true)
        axios.post(config.BASE_URL + 'showAllKitchens', {})
        .then(response => {
            let temp = []
            response.data.msg.map(info => {
                let kitchen = {
                    id: info.Kitchen.id,
                    name: info.Kitchen.name
                }
                temp.push(kitchen)
                return null
            })
            setKitchens(temp)
        })
        .catch(error => {
            console.log(error)
        })
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = (values) => {
        values.banner = bannerFile
        values.logo = logoFile
        values.cover = coverFile
        props.onSubmit(values)
        handleClose()
    }

    const handleDelete = () => {
        props.onDelete()
        handleClose()
    }

    const bannerSelectedHandler = event =>{
        setBanner(URL.createObjectURL(event.target.files[0]))
        setBannerFile(event.target.files[0])
    }
    const logoSelectedHandler = event =>{
        setLogo(URL.createObjectURL(event.target.files[0]))
        setLogoFile(event.target.files[0])
    }
    const coverSelectedHandler = event =>{
        setCover(URL.createObjectURL(event.target.files[0]))
        setCoverFile(event.target.files[0])
    }

    return ([
        props.restaurant ?
        <IconButton key={'0'} aria-label="Edit" onClick={handleClickOpen} >
            <Settings />
        </IconButton>: 
        <Button  key={'0'} color="primary" onClick={handleClickOpen}>
            <Add /> Add Restaurant
        </Button>
        ,
        <Dialog key={'1'} open={open} onClose={handleClose}>
            <DialogActions >
                <div className="formTitle">{props.restaurant ? 'Update ' + props.restaurant.name : 'Add New Restaurant'}</div>
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogContent>
                <Grid>
                    <Paper elevation={0} style={{width: 500}}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema}  onSubmit={values => handleSubmit(values)} >
                            {(formProps) => (
                                <Form noValidate>
                                    <div style={rowStyle}>
                                        <div className="bannerPreview" >
                                            <img alt='Restaurant Cover' src={banner} />
                                            <Button variant='outlined' className='previewButton' onClick={() => bannerRef.current?.click()}><PhotoCamera/>Select Banner</Button>
                                        </div>
                                        <input type='file' accept="image/png, image/jpeg" style={{display: 'none'}} onChange={bannerSelectedHandler} ref={bannerRef} />
                                    </div>
                                    <div style={rowStyle}>
                                        <div className="imagePreview" >
                                            <img alt='Restaurant Logo' src={logo} />
                                            <Button variant='outlined' className='previewButton' onClick={() => logoRef.current?.click()}><PhotoCamera/>Select Logo</Button>
                                        </div>
                                        <div className="imagePreview" >
                                            <img alt='Restaurant Cover' src={cover} />
                                            <Button variant='outlined' className='previewButton' onClick={() => coverRef.current?.click()}><PhotoCamera/>Select Cover</Button>
                                        </div>
                                        <input type='file' accept="image/png, image/jpeg" style={{display: 'none'}} onChange={logoSelectedHandler} ref={logoRef}/>
                                        <input type='file' accept="image/png, image/jpeg" style={{display: 'none'}} onChange={coverSelectedHandler} ref={coverRef}/>
                                    </div>
                                    <div style={rowStyle}>
                                        <Field as={TextField} name='name' label='Name' fullWidth
                                            error={formProps.errors.name && formProps.touched.name}
                                            helperText={<ErrorMessage name='name' />} required
                                            style={fieldStyle}
                                        />
                                        <Field as={TextField} name='slogan' label='Description' fullWidth
                                            error={formProps.errors.slogan && formProps.touched.slogan}
                                            helperText={<ErrorMessage name='slogan' />} required
                                            style={fieldStyle}
                                        />
                                    </div>
                                    <div style={rowStyle}>
                                        <Field as={TextField} name="email" label='Email' fullWidth
                                            error={formProps.errors.email && formProps.touched.email}
                                            helperText={<ErrorMessage name='email' />} required
                                            style={fieldStyle}
                                        />
                                        <FormControl fullWidth style={fieldStyle}>
                                            <InputLabel id="label">Kitchen</InputLabel>
                                            <Field as={Select} name="kitchen" labelId="label" fullWidth
                                                error={formProps.errors.kitchen && formProps.touched.kitchen} required
                                            >
                                                {
                                                    kitchens.map(data => {
                                                        return <MenuItem value={data.id} key={data.id}> { data.name } </MenuItem>
                                                    })
                                                }
                                            </Field>
                                            <FormHelperText error>{<ErrorMessage name="kitchen" />}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div style={rowStyle}>
                                        <FormControlLabel control={
                                            <Field as={Checkbox} checked={formProps.values.self_pickup } name='self_pickup' style={fieldStyle} color='primary' />
                                        } label="Allow self-pickup" />
                                    </div>
                                    <div className='buttonContainer'>
                                        { props.restaurant ? <Button color='secondary' onClick={handleDelete} >Delete Restaurant</Button> : null}
                                        <Button type='submit'  variant='contained'color='primary'> { props.restaurant ? 'Update restaurant' : 'Add Restaurant'} </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </DialogContent>
        </Dialog>
    ])
}

export default RestaurantForm